$color-white: #fff;
$color-black: #0D0D0D;
$color-ligth-gray: #e6e6e6;
$color-ac-blue: #0099CC;
$color-ac-dark-blue: #0078A1;
$color-dark-blue: #0066B3;
$color-yellow: #e8c120;
$color-dark-grey: #373D41;
$color-peach: #F68D76;
$color-orange: #F26522;
$color-ruby: #C8102E;
$color-violet: #6D2077;
$color-arctic-blue: #8DC8E8;
$color-leaf: #43B02A;
$color-petrol: #008C95;
$color-light-purple: #e9e7fd;
$color-golden: #FFC000;
$color-dark-grey-blue: #49606b;
$color-transparent-black: rgba(0, 0, 0, 0.5);
$color-alice-blue: #f0f8ff;
$color-dark-gunmetal: #16242C;
$sl-main-color: #24353F;

body {
  background-color: $color-dark-gunmetal !important;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #121C20 inset !important;
  -webkit-text-fill-color: white !important;
  font-family: 'system-ui';
}