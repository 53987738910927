@import "../../../shared/styles/SharedStyles.module.scss";

@media screen and (min-width: 1500px) {
  .schedulerContainer {
    margin: 0 -7%;
  }
}

@media screen and (min-width: 1900px) {
  .schedulerContainer {
    margin: 0 -20%;
  }
}

.tooltip_wrapper {
  color: $color-dark-grey;
  border: 1px solid $color-ligth-gray;
  border-radius: 5px;
}

.tooltip_content {
  padding: 0.5rem;
}

.tooltip_header {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $color-ligth-gray;

  & h6 {
    margin-bottom: 0;
    font-size: 1.2em;
    font-weight: 500;
  }
}

.tooltip_main {
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & p {
    margin-bottom: 0.5rem;
  }
}

.clock_icon {
  color: $color-ac-blue;
  position: relative;
  top: 2px;
  margin-right: 0.3rem;
}

.report_button_tooltip {
  z-index: 2200 !important;
}

.appointment_card_wrapper {
  font-size: 10px;
}
