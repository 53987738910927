@import "../../shared/styles/SharedStyles.module.scss";

.accordion_tab {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  align-items: center;

  & div:last-of-type {
    justify-self: end;
  }
}

.accordion_tab_progress_bar {
  width: 10rem;
  height: 1rem !important;
}

.progress_bar {
    width: 10rem;
    height: 1rem !important;
}

.success_row {
  background-color: $color-petrol !important;
  color: $color-white !important;

  &:hover {
      color: $color-ligth-gray !important;
  }
}

.error_row {
  background-color: $color-ruby !important;
  color: $color-white !important;

  &:hover {
      color: $color-ligth-gray !important;
  }
}

.in_progress_row {
  background-color: $color-golden !important;
  color: $color-white !important;

  &:hover {
      color: $color-ligth-gray !important;
  }
}

.record_message {
  width: 100%;
}

.record_paragraph {
  padding-left: 0.5rem;
  width: 100%;
}

.chip_container {
  height: 100%;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.record_row {
  display: flex;
  margin: 0.5rem 0;
  gap: .5rem;
}
