.data_table_header {
  display: flex;
  justify-content: space-between;
}

.dialog_footer {
  & button {
    margin-right: 1rem;
  }
}

.actions_wrapper {
  display: flex;
  align-content: flex-start;
  justify-content: space-around;
}

.actions_wrapper {
  display: flex;
  align-content: flex-start;
  justify-content: space-around;
  & button {
    margin-right: 0.5rem;
  }
}

.inactive {
  background-color: #575757be !important;
}

.custom_chip {
  background-color: rgba(36, 107, 187, 0.12);
  color: var(--warning-color-text);
}
