@import "../shared/styles/SharedStyles.module.scss";

.home_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  gap: 3.5%;
  

  & > div  {
    box-shadow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.075), 0 0 0 1px hsla(0, 0%, 0%, 0.05), 0 0.3px 0.4px hsla(0, 0%, 0%, 0.02), 0 0.9px 1.5px hsla(0, 0%, 0%, 0.045), 0 3.5px 6px hsla(0, 0%, 0%, 0.09);
    border-radius: 1rem;
    transition: transform 0.6s;
    width: 31%;
    height: 19rem;
    padding: 10px 15px;
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    font-family: 'system-ui';

    &:hover {
      transform: scale(1.02);
    }
  }
}

@media screen and (max-width: 1000px) {
  .home_wrapper {
    & > div {
      width: 48%;
    }
  }
}

@media screen and (max-width: 600px) {
  .home_wrapper {
    & > div {
      width: 100%;
    }
  }
}
