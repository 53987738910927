@import '../../shared/styles/SharedStyles.module.scss';

.cookies_container {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 2147483645;
    box-sizing: border-box;

    background-color: $color-black;

    .cookies_inner {
        max-width: fit-content;
        margin: 0 auto;
        padding: 2rem;
        text-align: center;

        .cookies_actions {
            margin-top: 2rem;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }
    }
}