@import "../../../shared/styles/SharedStyles.module.scss";

.leads_box {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .leads_box_row {
        padding: 12px 8px;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 18px;
        font-weight: 600;
      }
    
      .leads_success {
        background-color: $color-leaf;
      }
    
      .leads_info {
        background-color: $color-yellow;
      }
    
      .leads_warning{
        background-color: $color-orange;
      }
    
      .leads_error{
        background-color: $color-ruby;
      }
}
