@import '../../../shared/styles/SharedStyles.module.scss';

.poi_section {
    margin-top: 2%;
    padding: 1%;
    border: 1px solid $color-ligth-gray;
}

.poi_header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1%;
}

.dialog_footer {
    margin-top: .5rem;
    & button {
      margin-right: 1rem;
    }
}

.dialog_header {
    padding: 0 1rem 0 0;
}

.default_poi_row {
    background-color: $color-dark-grey-blue !important;
}

@media screen and (min-width: 1500px) {
    .dialog {
      width: 50%;
    }
}

.actions_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}