@import '../../../shared/styles/SharedStyles.module.scss';

.data_table_header {
	display: flex;
	justify-content: space-between;
}

.dialog_header {
	padding: 0 1rem 0 0;
}

.dialog_footer {
	& button {
		margin-right: 1rem;
	}
}

.actions_wrapper {
	display: flex;
	align-content: flex-start;
	justify-content: space-around;
}

.inactive {
	background-color: #575757be !important;
}

.actions_wrapper {
	display: flex;
	align-content: flex-start;
	justify-content: space-around;
	& button {
		margin-right: 0.5rem;
	}
}