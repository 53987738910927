@import "../../../shared/styles/SharedStyles.module.scss";

.data_table_header {
  display: flex;
  & button {
    margin-left: auto;
  }
}

.parts_table_wrapper {
  margin: 1rem 0;
}

.actions_wrapper {
  display: flex;
  align-content: flex-start;
  justify-content: space-around;
  & button {
    margin-right: .5rem;
  }
}

.actions_column {
  width: 3em;
}

@media screen and (max-width: 1000px) {
  .actions_column {
    padding-right: .2rem !important;

    & button:last-of-type {
      margin-right: 0 !important;
    }
  }
}

@media screen and (min-width: 1500px) {
  .dialog {
    width: 50%;
  }
}

.dialog_footer {
  margin-top: .5rem;
  & button {
    margin-right: 1rem;
  }
}

.invalid_row {
  & .mark_column_when_row_is_invalid {
    border: 2px solid $color-ruby !important;
  }
}
