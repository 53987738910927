.nav-item.show.dropdown {
  text-transform: uppercase;
}

.nav-item.dropdown {
  text-transform: uppercase;
}

.nav-item.show.dropdown .dropdown-menu.show {
  background-color: #008C95;
}

.nav_bar_main {
  background-color: #008C95;
}

.nav_bar_main  a {
  color: #fcfcfa;
}

.main_view {
  padding-top: 1rem;
}

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.p-error {
  color: #C8102E;
}

/* Dev Extreme Scheduler */

.dx-scheduler-cell-sizes-horizontal {  
  width: 20px !important;
}

table.dx-scheduler-header-panel th {
  border-color: #8794a8 !important;
}

.dx-scheduler-group-header {
  border-top-color: #8794a8 !important;
  border-right-color: #8794a8 !important;
  
}

.dx-scheduler-date-table-container > table > tbody > tr td {
  border-top-color: #8794a8 !important;
}

.dx-scheduler-date-time-indicator {
  background-color: #0099CC !important;
}

.dx-scheduler-date-time-indicator::before {
  color: #0099CC !important;
}

.dx-popup-wrapper.dx-scheduler-appointment-tooltip-wrapper .dx-popup-content .dx-list-item, .dx-scheduler-overlay-panel .dx-popup-content .dx-list-item {
  width: fit-content !important;
}

/* Dev Extreme Scheduler */
