@import '../../shared/styles/SharedStyles.module.scss';

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-transparent-black;
    z-index: 3000;
}

.loading_message {
    position: absolute;
    top: 40%;
    text-align: center;
    width: 100%;
    font-size: large;
    color: $color-alice-blue;
}