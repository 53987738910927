@import '../../../shared/styles/SharedStyles.module.scss';

.signature_canvas {
    background-color: $color-ligth-gray;
    height: 120px;
    width: 100%;
}

.signature_image {
    background-color: $color-ligth-gray;
}
